<template>
  <!--合同信息 模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <!--<el-form-->
        <!--    :model="where"-->
        <!--    label-width="60px"-->
        <!--    class="ele-form-search"-->
        <!--    @submit.native.prevent>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :lg="21" :md="12">-->
        <!--      <el-form-item label="服务商:">-->
        <!--        <el-select-->
        <!--            style="width: 300px !important;"-->
        <!--            clearable-->
        <!--            v-model="where.gender"-->
        <!--            placeholder="请选择服务商"-->
        <!--            class="ele-fluid">-->
        <!--          <el-option label="服务商1" value="1"/>-->
        <!--          <el-option label="服务商1" value="2"/>-->
        <!--          <el-option label="服务商 1" value="3"/>-->
        <!--        </el-select>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-form>-->
      </div>

      <div class="zhanshi" style="margin-top: 15px;">
        <el-row :gutter="15">
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">生效日期：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.start_time}}</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约周期：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.period}}年</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">到期自动签续：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.is_renew}}</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约状态：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">
              <!--<span style="display: inline-block; width: 8px;height: 8px;background: #25eb61;border-radius: 50%; vertical-align: middle"></span>-->
              <span style="vertical-align: middle; margin-left: 5px;">{{shuju.status}}</span>
            </span>
          </el-col>
        </el-row>
      </div>

      <!--开票资料-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              开票资料
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="Showageing(null)">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle">新增开票资料</span>
              </el-button>
            </div>
          </div>
        </div>
        <!-- 开票资料表格 -->
        <div style="margin-top: 15px;">
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="Showageing(scope.row)">编辑</el-link>
              <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                <el-link slot="reference" :underline="false">删除</el-link>
              </el-popconfirm>
            </template>
          </my-table>
        </div>
      </div>

      <!--签约记录-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              签约记录
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button @click="openEdit(null)">更新签约</el-button>
            </div>
          </div>
        </div>
        <!-- 签约记录表格 -->
        <div style="margin-top: 15px;">
          <my-table
              ref="myTable"
              :columns="columns1"
              :tableData="List1"
              :headerCellStyle="headerCellStyle"
              :total="total1"
              :customsFromWhere="where1"
              :loading="loading1"
              @select="select1"
              @ChangeSize="ChangeSize1"
              @currentChange="currentChange1"
          >

            <!--签约周期-->
            <template v-slot:period= "{ scope }">
              <span>{{scope.row.period}} 年</span>
            </template>

            <!--到期自动续签-->
            <template v-slot:is_renew= "{ scope }">
              <span v-if="scope.row.is_renew == 1" style="color: #36DD9E;">是</span>
              <span v-else>否</span>
            </template>

            <!--合同附件-->
            <template v-slot:agreement= "{ scope }">
              <span style="color: #FF9E1C;cursor: pointer;" @click="open(scope.row.agreement)">查看</span>
            </template>


            <!-- 不使用数据可不接收scope值 -->
            <!--<template v-slot:operationSlot= "{ scope }">-->
            <!--  <el-link :underline="false" type="warning">查看</el-link>-->
            <!--</template>-->
            <!--自定义列显示 示例-->
            <template v-slot:means_payments= "{ scope }">
              <div v-for="(item) in dict.type.valuationclearing_form">
                <span v-if="scope.row.means_payments == item.value">{{item.label}}</span>
              </div>
            </template>
          </my-table>
        </div>
      </div>


    </el-card>

    <!--开票资料弹窗-->
    <Edit :data="currenta" :visible.sync="showEdita" :id="id" :dict="dict" @done="getList"/>

    <!-- 编辑弹窗 -->
    <subscription-info :data="current" :id="id" :dict="dict" :visible.sync="showEdit" @done="getList1"/>

    <!--合同附件-->
    <el-dialog
        center
        title="合同附件"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div>
        <div v-for="(item,index) in appendixtothecontract" :key="index" style="cursor: pointer;margin-bottom: 10px;" @click="fileshow(item)">
          {{item}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!--<el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      </span>
    </el-dialog>
    <!--查看-->
    <el-dialog
        center
        :visible.sync="dialogVisible1"
        width="30%"
        :before-close="handleClose1">
      <div>
        查看
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 引入更新签约弹窗
import SubscriptionInfo from './components/subscription-info.vue';
import Edit from './components/edit.vue'
// 引入的接口
import {Deldel_invoice, getcontract_details, getreconciliation} from "@/api/custom";

export default {
  components:{
    Edit,
    SubscriptionInfo
  },
  props: {
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    // 字典数据
    dict:Object,
  },
  data(){
    return{
      //条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 水印配置表单数据
      form:{
        name:'',
      },

      // 示例图
      img: require('../../../../assets/images/home/jine.png'),

      // 更细签约数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,

      currenta:null,
      showEdita:false,

      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "公司名称",
          prop: "company_name",
          isShow: true
        },
        {
          label: "开票税号",
          prop: "tax_number",
          isShow: true
        },
        {
          label: "开户银行",
          prop: "deposit_bank",
          isShow: true
        },
        {
          label: "银行账号",
          prop: "bank_number",
          isShow: true
        },
        {
          label: "电话",
          prop: "phone",
          isShow: true
        },
        {
          label: "企业地址",
          prop: "enterprise_address",
          isShow: true
        },
        {
          label: "邮寄地址",
          prop: "delivery_address",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 签约表格数据
      where1:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      List1: [],
      // 总条目数
      total1: 0,
      //加载动画
      loading1:false,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "生效日期",
          prop: "start_time",
          isShow: true
        },
        {
          label: "签约周期",
          prop: "period",
          slot:'period',
          isShow: true
        },
        {
          label: "到期自动续签",
          prop: "is_renew",
          slot:'is_renew',
          isShow: true
        },
        {
          label: "合同附件",
          prop: "agreement",
          slot:'agreement',
          isShow: true
        },
        {
          label: "操作时间",
          prop: "operating_time",
          isShow: true
        },
        {
          label: "操作人",
          prop: "operating_user",
          isShow: true
        },
        {
          label: "结算方式",
          width: "200",
          prop: 'means_payments',
          slot: "means_payments",
          isShow: true
        },
      ],
      //合同附件
      dialogVisible:false,
      appendixtothecontract:[],
      dialogVisible1:false,

      shuju:{},

      pid:0,

    }
  },

  mounted() {
    console.log('合同信息父级id',this.id);
    this.getList();
    this.getList1();
  },

  methods:{
    // 获取列表
    getList(){
      getcontract_details(this.id,this.where).then(async (res) => {
        // 开票资料
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
        this.loading1 = false;
      })
    },

    getList1(){
      getreconciliation(this.id,this.where).then(async (res) => {
        console.log('合同信息列表',res);
        //签约记录
        // this.total1 = res.data.count;
        this.shuju = res.data;
        this.List1 = res.data.contract_award;
        this.loading1 = false;

      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
        this.loading1 = false;
      })
    },

    Showageing(row){
      this.currenta = row;
      this.showEdita = true;
    },
    // 点击更新签约按钮
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 删除
    del(row){
      Deldel_invoice(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },


    //签约记录表格事件
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val)
      this.where1.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize1(val){
      console.log('当前页面显示的条数',val);
      this.where1.limit = val;
      this.getList()
    },

    //合同附件查看
    open(row){
      console.log(row);
      this.appendixtothecontract = row;
      this.dialogVisible = true;
    },
    // 合同附件关闭
    handleClose(){
      this.dialogVisible = false;
    },
    fileshow(item){
      // this.dialogVisible1 = true;
      // this.dialogVisible = false;
    },
    // 合同附件查看关闭
    handleClose1(){
      this.dialogVisible1 = false;
      this.dialogVisible = true;
    },


  }

}
</script>

<style lang="scss" scoped>
.ageing{
  .ageing_b{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    margin-top: 15px;
  }

  .ageing_b_text_l{
    display: inline-block;
    width: 160px;
    font-size: 16px;
    font-weight: 400;
    color: #868792;
    text-align: right;
  }
  .ageing_b_text_r{
    margin-left: 15px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #050505;
  }
}

// 左右并行
.parallel:after{
  content: '';
  clear: both;
  display: block;
}
.parallel{
  .left{
    width: 30%;
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #050505;
    margin-top: 5px;
  }
  .right{
    width: 50%;
    float: left;
    margin-left: 15px;
  }
}

.right_img{
  width: 300px;
  height: 280px;
  padding: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


</style>
